import React, { useCallback, useEffect, useMemo, useState, Fragment, useRef } from 'react'
import Head from 'next/head'
import useLocale from '@/hooks/useLocale'
import { RevalidateTime } from '@/utils/config'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import type { GetStaticPropsContext } from 'next'
import { getTdk, ITdk } from '@/utils/common'
import { langMap } from '@/config'
import { useRouter } from 'next/router'
import s from '../styles/Home.module.scss'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import Button from '@/components/Button1'
import { ChevronDown } from '@/icons'
import dynamic from 'next/dynamic'
import IconBenzinga from '@/icons/featured/benzinga.svg'
import IconThestraitsstimes from '@/icons/featured/Thestraitsstimes.svg'
import IconForbes from '@/icons/featured/Forbes.svg'
import IconCointelegraph from '@/icons/featured/cointelegraph.svg'
import IconMedia from '@/icons/featured/media.svg'

const SolutionsForm = dynamic(() => import('@/components/SolutionsForm'), { ssr: false })
const CardSwipper = dynamic(() => import('@/components/newVersion/CardSwipper'))
const Solutions = dynamic(() => import('@/components/Solution/SolutionsTwo'))

type Iprops = {
  isH5: boolean
  tdkData?: ITdk
}
const Home = (props: Iprops) => {
  const { isH5, tdkData } = props
  const { t } = useLocale()
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [showPrivacyRemind, setShowPrivacyRemind] = useState(false)
  const [h5CurrentExpend, setH5CurrentExpend] = useState('')
  const itemsRef = useRef([] as any)

  const [h5Height, setH5Height] = useState(0)
  const router = useRouter()
  const clickRouter = useCallback(
    (url: string) => {
      if (url.indexOf('http') != -1) {
        window.open(url, '_blank')
      } else if (url && url.indexOf('http') == -1) {
        router.push(url)
      }
    },
    [router]
  )
  useEffect(() => {
    //控制提醒引导是否展示 且关闭之前页面不可滚动 关闭之后可滚动
    if (localStorage.getItem('Privacy-remind') !== '1') {
      setShowPrivacyRemind(true)
      localStorage.setItem('Privacy-remind', '1')
    }
    return () => { }
  }, [])

  const clickOpenForm = useCallback(() => {
    setIsOpenForm(true)
  }, [])

  const clickCloseForm = useCallback(() => {
    setIsOpenForm(false)
  }, [])

  const clickPrivacy = useCallback(() => {
    setShowPrivacyRemind(false)
  }, [])

  const onH5CurrentExpend = useCallback(
    (e: { stopPropagation: () => any }, id: string) => {
      if (h5CurrentExpend != id) {
        e.stopPropagation && e.stopPropagation()
        setH5Height(itemsRef.current[id] ? itemsRef.current[id].offsetHeight : 0)
        setH5CurrentExpend(id)
      }
    },
    [h5CurrentExpend]
  )
  const onH5CurrentClose = useCallback(
    (e: { stopPropagation: () => any }, id: string) => {
      if (h5CurrentExpend == id) {
        e.stopPropagation && e.stopPropagation()
        setH5Height(0)
        setH5CurrentExpend('')
      }
    },
    [h5CurrentExpend]
  )

  const clientsList = useMemo(
    () => [
      { title: t('Bitrue'), icon: '/images/home/clients/bitrue.svg' },
      { title: t('Hotcoin'), icon: '/images/home/clients/Hotcoin.svg' },
      { title: t('Hashkey Capital'), icon: '/images/home/clients/Haskey.svg' },
      { title: t('Pionex'), icon: '/images/home/clients/pionex.svg' },
      { title: t('Indodax'), icon: '/images/home/clients/INDODAX.svg' },
      { title: t('BitTrade'), icon: '/images/home/clients/BitTrade.svg' },
      { title: t('FomoPay'), icon: '/images/home/clients/FomoPay.svg' },
      { title: t('Zilliqa'), icon: '/images/home/clients/Zilliqa.svg' },
      { title: t('Exolo'), icon: '/images/home/clients/Exolo.svg' },
      { title: t('COINUP'), icon: '/images/home/clients/COINUP.svg' },
      { title: t('MetaTdex'), icon: '/images/home/clients/MetaTdex.svg' },
      { title: t('Xverse'), icon: '/images/home/clients/Xverse.svg' },
      { title: t('Coin Master'), icon: '/images/home/clients/CoinMaster.svg' },
      { title: t('DCC Payment'), icon: '/images/home/clients/DCCPayment.svg' },
      { title: t('Blofin'), icon: '/images/home/clients/Blofin.svg' },
      { title: t('Idax'), icon: '/images/home/clients/Idax.svg' },
      { title: t('Burency'), icon: '/images/home/clients/Burency.svg' },
      { title: t('Cobidex'), icon: '/images/home/clients/Cobidex.svg' }
    ],
    [t]
  )
  //clients
  const partnersList = useMemo(
    () => [
      { title: t('DBS Group'), icon: '/images/home/partners/DBSGroup.svg' },
      { title: t('Hashkey Group'), icon: '/images/home/partners/HashkeyGroup.svg' },
      { title: t('Amazon'), icon: '/images/home/partners/Amazon.svg' },
      { title: t('Huawei Cloud'), icon: '/images/home/partners/HuaweiCloud.svg' },
      { title: t('Alibaba Cloud'), icon: '/images/home/partners/AlibabaCloud.svg' },
      { title: t('CoinMarketCap'), icon: '/images/home/partners/CoinMarketCap.svg' },
      { title: t('CoinGecko'), icon: '/images/home/partners/CoinGecko.svg' },
      { title: t('Sumsub'), icon: '/images/home/partners/Sumsub.svg' },
      { title: t('Metatrust'), icon: '/images/home/partners/Metatrust.svg' },
      { title: t('Blockchain Association Singapore'), icon: '/images/home/partners/Blockchain.svg' },
      { title: t('Singapore FinTech Association'), icon: '/images/home/partners/Singapore.svg' },
      { title: t('National University of Singapore'), icon: '/images/home/partners/National.svg' },
      { title: t('Nanyang Technological University'), icon: '/images/home/partners/Nanyang.svg' },
      { title: t('Scroll'), icon: '/images/home/partners/Scroll.svg' }
    ],
    [t]
  )
  const page3 = useMemo(
    () => [
      {
        id: '1',
        img: isH5 ? '/images/solutions/solutions-module/h5/cex.png' : '/images/solutions/solutions-module/cex.png',
        text: t('Centralized Exchange'),
        link: '/product/exchange',
        onClick: () => {
          clickRouter('/product/exchange')
        }
      },
      {
        id: '2',
        img: isH5 ? '/images/solutions/solutions-module/h5/dex.png' : '/images/solutions/solutions-module/dex.png',
        text: t('Decentralized Exchange'),
        link: '/product/dex',
        onClick: () => {
          clickRouter('/product/dex')
        }
      },
      {
        id: '3',
        img: isH5 ? '/images/solutions/solutions-module/h5/web3-wallets.png' : '/images/solutions/solutions-module/web3-wallets.png',
        text: t('Hybrid Exchanges through Web3 Wallets'),
        onClick: () => {
          clickOpenForm()
        }
      }
    ],
    [clickOpenForm, clickRouter, t, isH5]
  )
  const page6 = useMemo(
    () => ({
      wrapperBg: '#fff',
      hover: true,
      list: [
        {
          id: '1',
          title: isH5 ? t('Digital Asset Tracing & Monitoring KYT Risk Control & Compliance Technology') : t('Digital Asset Tracing<br/>& Monitoring KYT Risk Control<br/>& Compliance Technology'),
          desc: t(
            "Streamline onboarding, boost compliance, and safeguard client assets with ChainUp's AI-powered Know Your Transaction risk control tech for efficient and frictionless banking in the digital age."
          ),
          img: isH5 ? '/images/solutions/solutions-module/h5/kyt.png' : '/images/solutions/solutions-module/kyt.png',
          bg: '#F2F8FF',
          onClick: () => {
            clickRouter('/product/kyt')
          },
          renderRightChaild: () => (
            <div className={s.page6_content}>
              <div className={cn(s.page6_title, 'colorMain')} data-aos="fade-up">
                {t('Explore ChainUp one-stop Solutions')}
              </div>
              <div className={s.page6_desc} data-aos="fade-up">
                {t(
                  'We provide a complete suite of blockchain solutions for businesses all within one platform. This includes infrastructure development and provision of essential resources, technical knowledge, and capabilities.'
                )}
              </div>
            </div>
          )
        },
        {
          id: '2',
          title: t('NFT Solutions'),
          desc: t('Unlock new customer engagement and brand loyalty with gamified rewards, exclusive experiences, and secure, tokenized assets, all powered by innovative NFT solutions.'),
          onClick: () => {
            clickRouter('/solution/GamingandMetaverse')
          },
          img: isH5 ? '/images/solutions/solutions-module/h5/nft.png' : '/images/solutions/solutions-module/nft.png',
          bg: '#F2F8FF',
          id1: '11',
          bg1: '#F3F6F9',
          title1: t('Bitwind Liquidity Solution'),
          desc1: t(
            'Empowered by technology, BitWind offers efficient, secure, and stable trading services. We assist institutional investors, traders, and digital asset exchanges in managing liquidity and enhancing the trading experience.'
          ),
          onClick1: () => {
            clickRouter('/product/liquidity')
          }
        },
        {
          id: '3',
          type: 2,
          items: [
            {
              id: '4',
              title: t('GPU Mining'),
              desc: t(
                'Be one of the first in the world to mine Aleo tokens which represents a cutting-edge blend of blockchain technology and privacy-preserving computation. Its focus on zero-knowledge proofs and the use of SNARKs positions it uniquely in the world of Digital Assets.'
              ),
              bg: '#F3F6F9',
              onClick: () => {
                clickRouter('/product/crypto-mining')
              }
            },
            {
              id: '5',
              title: isH5 ? t('Digital Asset Fund Management') : t('Digital Asset <br/>Fund Management'),
              desc: t('A unique digital asset risk management system powered by technology and financial data with an emphasis on the security and interests of investors.'),
              bg: '#F2F8FF',
              onClick: () => {
                clickOpenForm()
              }
            },
            {
              id: '6',
              title: t('Web3 Infrastructure'),
              desc: [t('Building blockchains'), t('Blockchain API'), t('Dedicated Node Hosting'), t('Node Validator'), t('Institutional Staking')],
              bg: '#F3F6F9',
              onClick: () => {
                clickRouter('https://cloud.chainup.com/blockapi')
              }
            }
          ]
        }
      ]
    }),
    [isH5, t, clickRouter, clickOpenForm]
  )

  return (
    <>
      <Head>
        <title>{tdkData?.title}</title>
        <meta name="keywords" content={tdkData?.keyword} />
        <meta name="description" content={tdkData?.description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={tdkData?.title} />
        <meta property="og:description" content={tdkData?.description} />
        <meta property="og:image" content="https://videos.chainup.com/share.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta name="twitter:title" content={tdkData?.title} />
        <meta name="twitter:description" content={tdkData?.description} />
        <meta name="twitter:image" content="https://videos.chainup.com/share.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:width" content="300" />
        <link rel="preload" href="/images/home/banner/bg.svg" as="image" />
      </Head>

      <SolutionsForm
        title={t('Request a demo')}
        isH5={isH5}
        isOpen={isOpenForm}
        onClose={() => {
          setIsOpenForm(false)
        }}
        onOpen={() => {
          setIsOpenForm(true)
        }}
      ></SolutionsForm>
      <div className={s.banner} >
        <div
          data-aos="fade-up"
          className={s.banner_title}
          dangerouslySetInnerHTML={{
            __html: isH5
              ? t('Your trusted partner for <span>enterprise-grade</span> blockchain and Web3 development')
              : t('Your trusted partner</br>for <span>enterprise-grade</span></br> blockchain and Web3 development')
          }}
        ></div>
        <Button className={cn(s.banner_btn)} link="" text={t('Talk to our experts')} type="green" arrow={false} onClick={clickOpenForm} onDblClick={clickCloseForm} />
        <div className={s.banner_icon} data-aos="fade-up">
          <Link href="/news/ChainUp-now-SOC-2-Type-1-certified-Blockchain-Solutions-Provider">
            <a target={'_blank'} className={s.banner_img} aria-label="/news/ChainUp-now-SOC-2-Type-1-certified-Blockchain-Solutions-Provider">
              <Image src="/images/home/banner/ios_type1.svg" layout="fill" alt="ios_type 1" priority ></Image>
            </a>
          </Link>
          <Link href="/news/chainup-is-now-soc-2-type-2-certified">
            <a target={'_blank'} className={s.banner_img} aria-label="/news/chainup-is-now-soc-2-type-2-certified">
              <Image src="/images/home/banner/ios_type2.svg" layout="fill" alt="ios_type 2" priority></Image>
            </a>
          </Link>
          <div className={cn(s.banner_icon, s.banner_icon_h5)}>
            <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
              <a target={'_blank'} className={s.banner_img} aria-label="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <Image src="/images/home/banner/iso1.svg" layout="fill" alt="iso1" priority></Image>
              </a>
            </Link>
            <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
              <a target={'_blank'} className={s.banner_img} aria-label="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <Image src="/images/home/banner/iso2.svg" layout="fill" alt="iso2" priority></Image>
              </a>
            </Link>
            <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
              <a target={'_blank'} className={s.banner_img} aria-label="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <Image src="/images/home/banner/iso3.svg" layout="fill" alt="iso3" priority></Image>
              </a>
            </Link>
          </div>
        </div>
        <div className={s.banner_featured} data-aos="fade-up">
          {t('Featured on')}
        </div>
        <div className={s.solutionsWarpper} data-aos="fade-up">
          <IconForbes></IconForbes>
          <IconThestraitsstimes></IconThestraitsstimes>
          <IconCointelegraph></IconCointelegraph>
          <IconBenzinga></IconBenzinga>
          <IconMedia></IconMedia>

        </div>
      </div >
      <div className={cn(s.page2, s.page2_after)}>
        <h1 className={cn('colorMain', s.page2_title)} data-aos="fade-up">
          {t('Trusted by Leading Organizations')}
        </h1>
        <div className={s.clientsList} data-aos="fade-up">
          {clientsList.map((item, index) => {
            return (
              !((isH5 && index == clientsList.length - 1) || (isH5 && index == clientsList.length - 2)) && (
                <div key={index} className={s.clientsItem}>
                  <div className={cn(s.clientsImg)}>
                    <Image layout="fill" src={item.icon} alt={item.title} loading="lazy" />
                  </div>
                  <div className={cn(s.clientsText)}>{item.title}</div>
                </div>
              )
            )
          })}

        </div>
      </div>
      {
        !isH5 ? (
          <div className={s.page3}>
            <div className={s.page3_content}>
              <div className={s.page3_title} data-aos="fade-up">
                {t('Digital Asset Exchange Platform Development')}
              </div>
              <div
                data-aos="fade-up"
                className={s.page3_desc}
                dangerouslySetInnerHTML={{ __html: t('Launch best-in-class, customized and secure digital assetexchanges,<br/>unlocking new revenue streams and revolutionize the finarcial system') }}
              ></div>

              <div className={s.page3_list}>
                {page3.map((item) => (
                  <div
                    data-aos="fade-up"
                    className={s.page3_item}
                    key={item.id}
                    onClick={() => {
                      item.onClick && item.onClick()
                    }}
                  >
                    <div className={s.page3_item_img}>
                      <Image src={item.img} layout="fill" alt={item.text} loading="lazy" ></Image>
                    </div>
                    <div className={s.page3_item_text}>{item.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={s.page3}>
            <div className={s.page3_content}>
              <div className={s.page3_title} data-aos="fade-up">
                {t('Digital Asset Exchange Platform Development')}
              </div>
              <div className={s.page3_desc} data-aos="fade-up">
                {t('Launch best-in-class, customized and secure digital assetexchanges,unlocking new revenue streams and revolutionize the finarcial system')}
              </div>
            </div>
            <CardSwipper
              list={page3}
              wrapperClass={s.page3_swipper}
              cardType={'custom'}
              cardItemClass={s.page3_card_item}
              renderChild={(item: any) => {
                return (
                  <div
                    className={s.page3_item}
                    key={item.id}
                    onClick={() => {
                      item.onClick && item.onClick()
                    }}
                  >
                    <div className={s.page3_item_img} data-aos="fade-up">
                      <Image src={item.img} layout="fill" alt={item.text} loading="lazy"></Image>
                    </div>
                    <div className={s.page3_item_text} data-aos="fade-up">{item.text}</div>
                  </div>
                )
              }}
            />
          </div>
        )
      }
      <div className={s.page4}>
        <Link href="/product/wallet">
          <div className={s.page4_item}>
            <div className={cn(s.page4_img, s.page4_img1)} data-aos="fade-up">
              <Image src={isH5 ? "/images/solutions/solutions-module/h5/wallet-big.png" : "/images/solutions/solutions-module/wallet-big.png"} layout="fill" alt="home wallet" loading="lazy"></Image>
            </div>
            <div className={cn(s.page4_content, s.page4_content1)}>
              <div className={s.page4_title} data-aos="fade-up" dangerouslySetInnerHTML={{ __html: t('Institutional Grade<br/>MPC Wallet-as-a-service') }}></div>
              <div className={s.page4_desc} data-aos="fade-up">
                {t(
                  "Empower secure multi-asset custody, attract digital asset-savvy clients, and unlock DeFi integration with ChainUp's seamless, scalable, and compliant Wallet-as-a-Service for institutions."
                )}
              </div>
            </div>
          </div>
        </Link>
        <Link href="/solution/AssetTokenization">
          <div className={cn(s.page4_item, s.page4_item2)}>
            <div className={cn(s.page4_content, s.page4_content2)}>
              <div className={s.page4_title} data-aos="fade-up">
                {t('Asset Tokenization')}
              </div>
              <div className={s.page4_desc} data-aos="fade-up">
                {t("Unlock new revenue streams and empower secure tokenization of real world assets with ChainUp's compliant and agile platform built for financial innovation.")}
              </div>
            </div>
            <div className={cn(s.page4_img, s.page4_img2)} data-aos="fade-up">
              <Image src={isH5 ? "/images/solutions/solutions-module/h5/sto-big.png" : "/images/solutions/solutions-module/sto-big.png"} layout="fill" alt="home asset" loading="lazy"></Image>
            </div>
          </div>
        </Link>
      </div>
      <div className={cn(s.page5, { [s.page5_ar]: router.locale == 'ar' })} style={{ background: `url(${isH5 ? '/images/home/h5/bg1.webp' : '/images/home/bg1.webp'}) center/ cover` }}>
        <div className={s.page5_content}>
          <div
            className={s.page5_title}
            data-aos="fade-up"
            dangerouslySetInnerHTML={{ __html: isH5 ? t('Turn Your Digital Asset Vision into Reality') : t('Turn Your Digital Asset Vision<br> into Reality') }}
          />
          <Button className={cn(s.page5_btn)} link="" text={t('Talk to us')} type="green" size="middle" arrow={false} onClick={clickOpenForm} onDblClick={clickCloseForm} />
        </div>
      </div >
      {
        !isH5 ? (
          <Solutions className={s.solutionsWrapper} list={page6.list} wrapperBg={page6.wrapperBg} hover={page6.hover} theme="dark" modal2Columns={3}></Solutions>
        ) : (
          <div className={s.page6}>
            <div className={cn(s.page6_title, 'colorMain')} data-aos="fade-up">
              {t('Explore ChainUp one-stop Solutions')}
            </div>
            <div className={s.page6_desc} data-aos="fade-up">
              {t(
                'We provide a complete suite of blockchain solutions for businesses all within one platform. This includes infrastructure development and provision of essential resources, technical knowledge, and capabilities.'
              )}
            </div>
            <div>
              {page6.list.map((item) => (
                <Fragment key={item.id}>
                  {item.title1 && (
                    <div
                      data-aos="fade-up"
                      className={s.page6_item}
                      style={{ background: item?.bg || '#095BE8' }}
                      onClick={() => {
                        h5CurrentExpend == item.id1 && item.onClick1 && item.onClick1()
                      }}
                    >
                      <div
                        className={s.page6_item_title}
                        onClick={(e) => {
                          onH5CurrentExpend(e, item.id1)
                        }}
                      >
                        <span> {item.title1}</span>
                        <div
                          className={cn(s.arrow, { [s.arrow_rotate]: h5CurrentExpend == item.id1 })}
                          onClick={(e) => {
                            onH5CurrentClose(e, item.id1)
                          }}
                        >
                          <ChevronDown />
                        </div>
                      </div>
                      <div
                        className={cn(s.page6_item_desc, s.page6_item_change_height, { [s.page6_item_height_hidden]: h5CurrentExpend != item.id1 })}
                        style={{ height: h5CurrentExpend == item.id1 ? h5Height : '0px' }}
                      >
                        <div
                          className={s.page6_item_change_height_content}
                          ref={(el) => {
                            itemsRef.current[item.id1] = el
                          }}
                        >
                          {item.desc}
                        </div>
                      </div>
                    </div>
                  )}
                  {item.title && (
                    <div
                      data-aos="fade-up"
                      className={s.page6_item}
                      style={{ background: item?.bg || '#095BE8' }}
                      onClick={() => {
                        h5CurrentExpend == item.id && item.onClick && item.onClick()
                      }}
                    >
                      <div
                        className={s.page6_item_title}
                        onClick={(e) => {
                          onH5CurrentExpend(e, item.id)
                        }}
                      >
                        <span dangerouslySetInnerHTML={{ __html: item.title }} />
                        <div
                          className={cn(s.arrow, { [s.arrow_rotate]: h5CurrentExpend == item.id })}
                          onClick={(e) => {
                            onH5CurrentClose(e, item.id)
                          }}
                        >
                          <ChevronDown />
                        </div>
                      </div>
                      <div className={cn(s.page6_item_change_height, { [s.page6_item_height_hidden]: h5CurrentExpend != item.id })} style={{ height: h5CurrentExpend == item.id ? h5Height : '0px' }}>
                        <div
                          className={s.page6_item_change_height_content}
                          ref={(el) => {
                            itemsRef.current[item.id] = el
                          }}
                        >
                          <div className={s.page6_item_desc}>{item.desc}</div>
                          <div className={s.page6_item_img}>
                            <Image src={item.img} layout="fill" alt={item.title} loading="lazy"></Image>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {item.items?.map((i) => (
                    <div
                      data-aos="fade-up"
                      className={s.page6_item}
                      key={i.id}
                      style={{ background: i?.bg || '#095BE8' }}
                      onClick={() => {
                        h5CurrentExpend == i.id && i.onClick && i.onClick()
                      }}
                    >
                      <div
                        className={s.page6_item_title}
                        onClick={(e) => {
                          onH5CurrentExpend(e, i.id)
                        }}
                      >
                        <span> {i.title}</span>
                        <div
                          className={cn(s.arrow, { [s.arrow_rotate]: h5CurrentExpend == i.id })}
                          onClick={(e) => {
                            onH5CurrentClose(e, i.id)
                          }}
                        >
                          <ChevronDown />
                        </div>
                      </div>
                      <div
                        className={cn(s.page6_item_desc, s.page6_item_change_height, { [s.page6_item_height_hidden]: h5CurrentExpend != i.id })}
                        style={{ height: h5CurrentExpend == i.id ? h5Height : '0px' }}
                      >
                        <div
                          className={s.page6_item_change_height_content}
                          ref={(el) => {
                            itemsRef.current[i.id] = el
                          }}
                        >
                          {Array.isArray(i.desc) ? (
                            i.desc.map((descItem: string, desci: number) => {
                              return <p key={desci}>{descItem}</p>
                            })
                          ) : (
                            <p>{i.desc}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
          </div>
        )
      }
      <div className={cn(s.page2, s.page7)}>
        <h1 className={cn('colorMain', s.page2_title)} data-aos="fade-up">
          {t('Our Partners')}
        </h1>
        <div className={cn(s.clientsList, s.partnersList)}>
          {partnersList.map((item, index) => {
            return (
              !((isH5 && index == 1) || (isH5 && index == partnersList.length - 1)) && (
                <div key={index} className={cn(s.clientsItem, s.partnersItem)} data-aos="fade-up">
                  <div className={cn(s.clientsImg)}>
                    <Image layout="fill" src={item.icon} alt={item.title} loading="lazy" />
                  </div>
                  <div className={cn(s.clientsText)}>{item.title}</div>
                </div>
              )
            )
          })}
        </div>
      </div>

      {
        showPrivacyRemind && (
          <div className={s.privacyReminderContent}>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'We use cookies to make your interactions with our website more meaningful. They help us better understand how our websites are used, so we can tailor content for you. For more information about the different cookies we are using, read the <strong>Privacy Statement.</strong>'
                )
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('To change your cookie settings and preferences, see the <strong>Cookie Consent Manager</strong>.')
              }}
            />

            <div className={s.buttonWarpper}>
              <button className={cn(s.button, s.rejectButton)} onClick={clickPrivacy}>
                {t('Reject')}
              </button>
              <button className={cn(s.button, s.acceptButton)} onClick={clickPrivacy}>
                {t('Accept')}
              </button>
            </div>
          </div>
        )
      }
    </>
  )
}
export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const tdkData = await getTdk('/index', langMap[locale as string])
  return {
    props: {
      tdkData,
      ...(await serverSideTranslations(locale as string))
    },
    revalidate: RevalidateTime
  }
}
export default Home
